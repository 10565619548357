import { Alert, Snackbar } from "@mui/material";
import React, { useContext, useState } from "react";

const ToastContext = React.createContext(null);

//Create a context affect wrapper so that we can push toast messages from anywhere in the app
//Todo make this handle multiple toasts
const ToastProvider = ({ children }) => {
  const [open, setOpen] = useState(null);
  const [toast, setToast] = useState(null);
  const [severity, setSeverity] = useState("success");
  const closeToast = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const showToast = (message, options) => {
    setToast(message);
    setSeverity(options && options.severity ? options.severity : "success");
    setOpen(true);
  };

  return (
    <ToastContext.Provider value={{ showToast, closeToast }}>
      {children}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={closeToast}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={closeToast} severity={severity} sx={{ width: "100%" }}>
         {toast}
        </Alert>
      </Snackbar>
    </ToastContext.Provider>
  );
};

const useToast = () => {
  const toast = useContext(ToastContext);
  if (toast == null) {
    throw new Error("useToast() called outside of a ToastProvider?"); // an alert is not placed because this is an error for the developer not the user
  }

  return toast;
};

export { ToastProvider, useToast };
