import { collection } from "firebase/firestore"
import { useCallback, useEffect, useRef, useState } from "react"

import { getDictionaries } from "../api/dictionary"

export function useDictionary() {
  const [loading, setLoading] = useState(true)
  const collections = useRef([])
  const instances = useRef([])
  const definitions = useRef([])

  useEffect(() => {
    if (collections.current.length === 0) {
      getDictionaries().then((result) => {
        console.log("result", result)
        collections.current = result.data.collections
        instances.current = result.data.instances
        definitions.current = result.data.definitions
        setLoading(false)
      })
    } else {
      setLoading(false)
    }
  }, [])

  const getCollectionByUrn = useCallback(
    (urn) => {
      return collections.current.find((collection) => collection.urn === urn)
    },
    [collections]
  )
  const getInstanceByUrn = useCallback(
    (urn) => {
      return instances.current.find((instance) => instance.urn === urn)
    },
    [instances]
  )
  const getDefinitioneByUrn = useCallback(
    (urn) => {
      return definitions.current.find((definition) => definition.urn === urn)
    },
    [definitions]
  )

  return { loading, getCollectionByUrn, getInstanceByUrn, getDefinitioneByUrn }
}
