import { Alert, Backdrop, CircularProgress, Paper } from "@mui/material"
import Button from "@mui/material/Button"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import Link from "@mui/material/Link"
import Typography from "@mui/material/Typography"
import React, { useEffect, useState } from "react"
import { FormContainer, TextFieldElement, useForm } from "react-hook-form-mui"
import { Link as RouterLink } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import { useLoaderData } from "react-router-dom"

import { respondToCode } from "../api/connect"

function EnterCode({ params }) {
  const navigate = useNavigate()
  const { inputcode } = useLoaderData()

  const { setValue, getValues, handleSubmit, control , setFocus,  formState: { errors }} = useForm()

  const [loading, setLoading] = useState(false)
  const [showError, setShowError] = useState(false)
  const [error, setError] = useState("")

  const [code, setCode] = useState("")
  const [name, setName] = useState("")
  const [company, setCompany] = useState("")

  useEffect(() => {
    setValue("code", inputcode)
    localStorage.getItem("name") &&
      setValue("name", localStorage.getItem("name"))

    localStorage.getItem("company") &&
      setValue("company", localStorage.getItem("company"))

  }, [inputcode, setValue])

  const onSuccess = (data) => {
    setLoading(true)
    console.log(data)
    localStorage.setItem("name", data.name)
    localStorage.setItem("company", data.company)
    respondToCode(data.code, data.name, data.company).then((result) => {
      console.log(result)
      setLoading(false)
      if (result.error) {

        setFocus("code");
        setError(result.message)
        setShowError(true)
      } else {
    
        sessionStorage.setItem("connection", result.data.connection)
        navigate(`/connection/${result.data.id}`)
      }
    })
  }
  useEffect(() => {

    if (!localStorage.getItem("name") && inputcode?.length >= 1 ) {
      setFocus("name")
    } else {
    setFocus("code")
    }
  }, [setFocus, inputcode])

  useEffect(() => {
    const firstError = Object.keys(errors).reduce((field, a) => {
      return !!errors[field] ? field : a;
    }, null);
  
    if (firstError) {
      setFocus(firstError);
    }
  }, [errors, setFocus]);
    

  return (
      <Grid
      style={{marginTop: 10}}
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
        rowGap={4}
        rowSpacing={{ xs: 5, md: 3 }}
        columnGap={{ xs: 2, md: 3 }}
        minWidth={{ xs: 300, sm: 400, md: 600 }}
        direction="column"
        alignItems="center"
        justifyContent="center">
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <Grid item xs style={{ padding: 10, minWidth: 300 }}>
              <Typography
                align="center"
                mt={3}
                component="h1"
                variant="h5"
                sx={{
                  justifyContent: "center",
                  textAlign: "center",
                  color: "text.secondary"
                }}>
                Connect to a SYNQ user
              </Typography>
            </Grid>
            <FormContainer
              defaultValues={{ code, name }}
              onSuccess={handleSubmit(onSuccess)}>
              <Grid item xs style={{ padding: 10, minWidth: 300 }}>
                <TextFieldElement
                  required
                  control={control}
                  fullWidth
                  id="code"
                  type="text"
                  label="Connection code"
                  name="code"
                  autoComplete="off"
                />
              </Grid>

              <Grid item xs style={{ padding: 10, minWidth: 300 }}>
                <TextFieldElement
                  required
                  control={control}
                  fullWidth
                  id="name"
                  type="text"
                  label="Your name"
                  name="name"
                  autoComplete="off"
                />
              </Grid>

              <Grid item xs style={{ padding: 10, minWidth: 300 }}>
                <TextFieldElement
                
                  control={control}
                  fullWidth
                  id="company"
                  type="text"
                  label="Your company name"
                  name="company"
                  autoComplete="off"
                />
              </Grid>


              <Grid item xs style={{ padding: 10, minWidth: 300 }}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ backgroundColor: "primary" }}>
                  Connect
                </Button>
              </Grid>

              <Grid item xs style={{ padding: 10, minWidth: 300 }}>
                <Alert sx={{ display: !showError && "none" }} severity="error">
                  {error}
                </Alert>
              </Grid>
            </FormContainer>
          </>
        )}
      </Grid>
  )
}
export default EnterCode
