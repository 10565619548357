import { createTheme } from "@mui/material"

//Create the MUI theme
const theme = createTheme({
  palette: {
    mode: "light",
    background: {
      default: "white",
      secondary: {
        color: "white"
      }
    },
    primary: {
      main: "#4F0778"
    },
    text: {
      primary: "#888484"
    }
  },
  typography: {
    fontFamily: "Muli,sans-serif",
    fontWeightLight: 300,
    fontWeightMedium: 700,
    fontWeightRegular: 500,
    fontWeightBold: 900,
    h5: {
      fontFamily: "Nunito,sans-serif",
      fontWeight: 700
    }
  },

  //I was and still am having issues with the autofill background color
  components: {
    MuiCssBaseline: {
      styleOverrides: `
          input:-webkit-autofill,
          input:-webkit-autofill:hover, 
          input:-webkit-autofill:focus, 
          input:-webkit-autofill:active  {
            -webkit-box-shadow: 0 0 0 100px #202030 inset !important;
          }
        `
    },
    MuiPaper: {
      defaultProps: {
        elevation: 4
      },
      styleOverrides: {
        root: {
          borderRadius: 5,
          elevation: 32
        }
      }
    },
    MuiAppBar: {
      defaultProps: {
        elevation: 0
      },
      styleOverrides: {
        root: {
          borderRadius: 0
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 5
        }
      }
    }
  }
})

export { theme }
