export const getDictionaries = async (code) => {
 
  
    try {
      const result = await fetch(
        `${process.env.REACT_APP_API}/dictionary`,
        {
          method: "GET",
        }
      )
      const resultJSON = await result.json()
      console.log("resultJSON", resultJSON)
      return resultJSON

    } catch (error) {
      return {
        error: {
          code: "error",
          message: "Something went wrong. Please try again later."
        }
      }
    }
  }