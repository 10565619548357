export const respondToCode = async (code, name, company) => {
  const headers = await generateHeaders(
    JSON.stringify({ code, name, company }),
    "synq-nwvsu-1"
  )

  console.log("headers", code, name)

  try {
    const result = await fetch(`${process.env.REACT_APP_API}/sendmydetails`, {
      method: "POST",
      body: JSON.stringify({ code, name, company }),
      headers
    })
    const resultJSON = await result.json()
    return resultJSON
  } catch (error) {
    return {
      error: {
        code: "error",
        message: "Something went wrong. Please try again later."
      }
    }
  }
}

export const getDownloadUrl = async (code, filename) => {
  const headers = await generateHeaders(
    JSON.stringify({ code }),
    "synq-nwvsu-1"
  )

  try {
    const result = await fetch(
      `${
        process.env.REACT_APP_API
      }/sendmydetails/generateDownloadUrl/${code}/${sessionStorage.getItem(
        "connection"
      )}`,
      {
        method: "POST",
        body: JSON.stringify({ filename }),
        headers
      }
    )

    const blob = await result.blob()
    const url = window.URL.createObjectURL(blob)
    var fileLink = document.createElement("a")
    fileLink.href = url
    fileLink.download = filename.split("/").pop()
    fileLink.click()

    return {}
  } catch (error) {
    return {
      error: {
        code: "error",
        message: "Something went wrong. Please try again later."
      }
    }
  }
}

export const getCode = async (code) => {
  const headers = await generateHeaders(
    JSON.stringify({ code }),
    "synq-nwvsu-1"
  )

  try {
    const result = await fetch(
      `${
        process.env.REACT_APP_API
      }/sendmydetails/${code}/${sessionStorage.getItem("connection")}`,
      {
        method: "GET",
        headers
      }
    )
    const resultJSON = await result.json()
    console.log("resultJSON", resultJSON)
    return resultJSON
  } catch (error) {
    return {
      error: {
        code: "error",
        message: "Something went wrong. Please try again later."
      }
    }
  }
}

const generateHeaders = async (data) => {
  //   const sig = crypto
  //     .createHmac("sha256", "69844511-bcbe-420e-ab81-5b6cb12e9456")
  //     .update(data)
  //     .digest("hex")

  let sig

  let secret = "69844511-bcbe-420e-ab81-5b6cb12e9456" // the secret key
  let enc = new TextEncoder("utf-8")
  let body = data
  let algorithm = { name: "HMAC", hash: "SHA-256" }

  let key = await crypto.subtle.importKey(
    "raw",
    enc.encode(secret),
    algorithm,
    false,
    ["sign", "verify"]
  )
  let signature = await crypto.subtle.sign(
    algorithm.name,
    key,
    enc.encode(body)
  )
  sig = btoa(String.fromCharCode(...new Uint8Array(signature)))

  console.log("sig", sig)

  return {
    "Content-Type": "application/json",
    "X-Hook-Signature": `sha256=${sig}`,
    "Access-Control-Allow-Origin": "*",
    mode: "no-cors", // no-cors, *cors, same-origin
    credentials: "include"
  }
}
