import { Alert, Backdrop, CircularProgress, Paper } from "@mui/material"
import Button from "@mui/material/Button"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import Link from "@mui/material/Link"
import Typography from "@mui/material/Typography"
import React, { useEffect, useState } from "react"
import { FormContainer, TextFieldElement, useForm } from "react-hook-form-mui"
import { Link as RouterLink } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import { useLoaderData } from "react-router-dom"

import { getCode, respondToCode } from "../api/connect"
import { useInterval } from "../hooks/useInterval"

function WaitForUser({ params }) {
  const navigate = useNavigate()
  const { id } = useLoaderData()

  const [loading, setLoading] = useState(false)
  const [showError, setShowError] = useState(false)
  const [error, setError] = useState("")
  const [recheck, setRecheck] = useState(false)

  useInterval(() => {
    getCode(id).then((result) => {
      if (result.error) {
        setError(result.message)
        setShowError(true)
        
      } else {
        if (result.data?.cancelled || result.data?.objects?.urn) {
          navigate(`/data/${id}`)
        }
      }
    })
  }, 1000)

  const cancel = () => {
    navigate(`/`)
  }
  return (
    <Grid
      style={{ marginTop: 10 }}
      container
      spacing={{ xs: 2, md: 3 }}
      columns={{ xs: 4, sm: 8, md: 12 }}
      rowGap={4}
      rowSpacing={{ xs: 5, md: 3 }}
      columnGap={{ xs: 2, md: 3 }}
      minWidth={{ xs: 300, sm: 400, md: 600 }}
      direction="column"
      alignItems="center"
      justifyContent="center">
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Grid item xs style={{ minWidth: 300 }}>
            <Typography
              align="center"
              mt={3}
              component="h5"
              variant="h5"
              sx={{
                justifyContent: "center",
                textAlign: "center",
                color: "text.secondary"
              }}>
              Waiting for the SYNQ user to send...
            </Typography>
          </Grid>
          <CircularProgress />
          <FormContainer onSuccess={cancel}>
            <Grid item xs style={{ minWidth: 300 }}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, backgroundColor: "warning" }}>
                Cancel request
              </Button>
            </Grid>
          </FormContainer>
        </>
      )}

      <Grid item xs style={{ padding: 10, minWidth: 300 }}>
        <Alert sx={{ display: !showError && "none" }} severity="error">
          {error}
        </Alert>
      </Grid>
    </Grid>
  )
}
export default WaitForUser
