import "./App.css"

import CssBaseline from "@mui/material/CssBaseline"
import { ThemeProvider, createTheme } from "@mui/material/styles"
import React from "react"
import { FormProvider } from "react-hook-form"
import {
  Route,
  BrowserRouter as Router,
  RouterProvider,
  Routes,
  createBrowserRouter
} from "react-router-dom"

import EnterCode from "./Pages/EnterCode"
import { ToastProvider } from "./Providers/ToastProvider"
import { theme } from "./api/utils"
import WaitForUser from "./Pages/WaitForUser"
import DisplayData from "./Pages/DisplayData"

//The world starts here
function App() {
  const router = createBrowserRouter([
    {
      path: "/data/:id",
      loader: ({ params }) => {
        return { id: params.id }
      },
      element: <DisplayData />
    },
    {
      path: "/connection/:id",
      loader: ({ params }) => {
        return { id: params.id }
      },
      element: <WaitForUser />
    },
    {
      path: "/",
      loader: ({ params }) => {
        return { inputcode: ''}
      },
      element: <EnterCode />
    },
    {
      path: "/:code",
      loader: ({ params }) => {
        console.log("myparams", params)
        return { inputcode: params.code }
      },
      element: <EnterCode />
    }
  ])

  return (
    <ThemeProvider theme={theme}>
      <ToastProvider>
        <FormProvider>
          <CssBaseline />
          <div className="app">
            <RouterProvider router={router} />
            {/* <Router>
              <Routes>
                <Route path="/" element={<EnterCode />} />
              </Routes>
            </Router> */}
          </div>
        </FormProvider>
      </ToastProvider>
    </ThemeProvider>
  )
}
export default App
